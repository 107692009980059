import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// const link = document.createElement('link');
// link.rel = 'preload';
// link.as = 'image';
// link.href = "../media/logo_navbar.jpg";
// link.setAttribute('importance', 'high'); // Ensure high priority
// document.head.appendChild(link);

// Function to preload an image
// function preloadImage(imageUrl) {
//   const link = document.createElement('link');
//   link.rel = 'preload';
//   link.as = 'image';
//   link.href = imageUrl;
//   link.setAttribute('importance', 'high'); // Ensure high priority
//   // Append the link to the head of the document
//   document.head.appendChild(link);
// }
//
// // Call the function with the image URL
// preloadImage('../media/logo_navbar.jpg');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
